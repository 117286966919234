import { LinkInstance } from "./LinkInstance";
class LinksPlugin {
    constructor() {
        this.id = "links";
    }
    getPlugin(container) {
        return new LinkInstance(container);
    }
    loadOptions() {
    }
    needsPlugin() {
        return true;
    }
}
export async function loadPlugin(engine) {
    const plugin = new LinksPlugin();
    await engine.addPlugin(plugin);
}
